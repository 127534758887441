import React, { useState, useEffect } from "react";
import {
    GelBoxLayout,
    GelButton,
    GelContainerLite,
    GelForm,
    GelFormField,
    GelTextInput,
    useGelFormData,
    GelSpinner,
} from "@tal-gel/components";
import { useNavigate } from "react-router-dom";
import { API } from "../constants/constants";
import axios from "axios";
import { getGelTokens } from "@tal-gel/theming";
import { GET_TFNPAGE_CONTENT_QUERY } from "../graphql/graphql-contentful";
import Header from "../components/header";
import Footer from "../components/footer";

const AutomationPage = () => {
    const {
        global: { sizeBaseX15, themeColorWhite, borderSizeSm, themeColorGrayT20 },
    } = getGelTokens();

    const [contentfulData, setcontenfulData] = useState<any>(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { formData, onFormDataChange } = useGelFormData({
        username: "",
        password: "",
    });

    const onSubmit = () => {
        if (formData.username !== "" && formData.password !== "") {
            navigate("/tfn-form", {
                state: {
                    auth: true,
                },
            });
        }
    };

    useEffect(() => {
        setIsLoading(true);

        //contentful call
        axios
            .post(API.CONTENTFUL_URL, {
                query: GET_TFNPAGE_CONTENT_QUERY,
            })
            .then(async (response) => {
                setcontenfulData(response.data?.data.items.items[0]);
            });

        const queryParams = new URLSearchParams(window.location.href);
        const origin = queryParams.get("origin");

        const pattern = /info=([^&]+)/;
        const match = window.location.href.match(pattern);
        const info = match ? match[1] : "";
        const params = info.split("_");

        const claimId = params[1];
        const requirementId = params[2];

        if (claimId && requirementId && origin) {
            sessionStorage.setItem("claimId", claimId);
            sessionStorage.setItem("requirementId", requirementId);
            sessionStorage.setItem("origin", origin);
        }

        setIsLoading(false);
    }, []);

    return (
        <div>
            <Header title="Automation Login" />
            <GelContainerLite>
                {isLoading && <GelSpinner large overlay />}
                <GelBoxLayout
                    gap="medium"
                    alignment="start"
                    space="auto"
                    distribution="spaceBetween"
                    vertical="true"
                    padding="large"
                    margin="large"
                    style={{
                        marginTop: `-${sizeBaseX15}px`,
                        backgroundColor: themeColorWhite,
                        border: `${borderSizeSm}px solid`,
                        borderColor: themeColorGrayT20,
                    }}
                >
                    <GelForm labelAtTop onSubmit={onSubmit}>
                        <GelFormField label="Username">
                            <GelTextInput
                                name="username"
                                defaultValue={formData.username}
                                onChange={onFormDataChange}
                                noFormat
                                required
                            />
                        </GelFormField>

                        <GelFormField label="Password">
                            <GelTextInput
                                name="password"
                                defaultValue={formData.passcode}
                                onChange={onFormDataChange}
                                required
                            />
                        </GelFormField>

                        <GelBoxLayout alignment="start" gap="small">
                            <GelFormField>
                                <GelButton
                                    name="" // Makes it a managed component
                                    primary
                                    medium
                                    submit
                                    onSubmit={onSubmit}
                                >
                                    {contentfulData?.mfaSubmitButtonText}
                                </GelButton>
                            </GelFormField>
                        </GelBoxLayout>
                    </GelForm>
                </GelBoxLayout>
            </GelContainerLite>
            <Footer />
        </div>
    );
};

export default AutomationPage;
