export const GET_MFAPAGE_CONTENT_QUERY = `
query GetMmcTfnPageItemsCollectionForFund($fundName: [String]) {
  items: mmcTfnPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
    items {
      mfaPageTitle
      mfaDescription
      mfaFormLabel
      mfaResendButtonText
      mfaSubmitButtonText
      errorMessageContent
    }
  }
}
`;

export const GET_TFNPAGE_CONTENT_QUERY = `
query GetMmcTfnPageItemsCollectionForFund($fundName: [String]) {
  items: mmcTfnPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
    items {
      tfnQuestionnaire
      errorMessageContent
      tfnPageTitle
      tfnSubmitButtonText
      tfnCancelButtonText
      mfaSubmitButtonText
    }
  }
}
`;

export const GET_SUCCESSPAGE_CONTENT_QUERY = `
query GetMmcTfnPageItemsCollectionForFund($fundName: [String]) {
  mmcTfnPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
    items {
      successPageTitle
      successPageText1
      successPageText2
      footerPrivacyLink
      footerDisclaimerLink
      footerText1
      footerText2
    }
  }
}
`;
