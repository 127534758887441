import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';

const ServerError = () => {
    const { global: { themeColorTextDanger } } = getGelTokens()

    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
                <GelLabel style={{ color: themeColorTextDanger }}>
                    <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />
                    Sorry we are not able to process your request. Please try again later.</GelLabel>
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default ServerError;