import React, { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../common/authentication/secure.route";
import ServerError from "../components/ServerError";
import UnAuthorized from "../components/unauthorized";
import AutomationPage from "./automationPage";
import SuccessPage from "./successPage";
import TfnForm from "./tfn/tfnForm";
import VerifyPasscode from "./tfn/verifyPasscode";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/verify-passcode" element={<VerifyPasscode />} />
        <Route path="/tfn-form" element={<TfnForm />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/automation-login" element={<AutomationPage />} />
      </Route>
      <Route path="/error" element={<ServerError />} />
      <Route path="/unauthorized" element={<UnAuthorized />} />
      <Route path="/" element={<Navigate to="/verify-passcode" />} />
    </Routes>
  );
};

export default AppRoutes;
