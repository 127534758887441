import { GelContainerLite, GelLink, GelColumnLayout, GelParagraph } from "@tal-gel/components";
import brandLogo from "../assets/images/tal-logo.svg";
import { getGelTokens } from "@tal-gel/theming";

const Footer = () => {
    return (
        <GelContainerLite>
            <GelColumnLayout alignment="center" style={{ "text-align": "center" }}>
                <img
                    id="brand-logo"
                    alt="logo"
                    src={brandLogo}
                    style={{
                        display: "block",
                        margin: "auto",
                        marginTop: `${getGelTokens().global.sizeBaseX16}px`,
                    }}
                />
                <div>
                    <GelLink style={{ marginRight: "10px" }}>Insurer Privacy </GelLink> |{" "}
                    <GelLink style={{ marginLeft: "10px" }}> Insurer Disclaimer</GelLink>
                </div>
                <GelParagraph>
                    {new Date().getFullYear()} (c) TAL Life Limited (ABN 70 050 109 450 AFSL 237848).
                    <br />
                    Need Help? CALL 1800 101 019 or EMAIL groupclaims@tal.com.au
                    https://tfn.uat.urlife.net.au/#/
                </GelParagraph>
            </GelColumnLayout>
        </GelContainerLite>
    );
};

export default Footer;
