import styled from "@emotion/styled";
import {
    GelAutoCompleteInput,
    GelBoxLayout,
    GelButton,
    GelCaption,
    GelCheckbox,
    GelCheckboxGroup,
    GelColumnLayout,
    GelContainerLite,
    GelEmailInput,
    GelForm,
    GelFormField,
    GelIcon,
    GelLabel,
    GelLink,
    GelModal,
    GelNumberInput,
    GelParagraph,
    GelSelect,
    GelSpinner,
    GelStandardDateInput,
    GelTextInput,
    useGelFormData,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { MemberAddressResponseType, searchAddressRequest } from "../../common/api/address.request";
import Footer from "../../components/footer";
import Header from "../../components/header";
import TooltipModal from "../../components/TooltipModal";
import {
    LoanTooltip,
    PaidTooltip,
    ResidentTooltip,
    TaxFreeTooltip,
    TfnNumberTooltip,
} from "../../components/TooltipTemplates";
import { API } from "../../constants/constants";
import { SUBMIT_TFN_FORM_QUERY } from "../../graphql/graphql-bff";
import { GET_TFNPAGE_CONTENT_QUERY } from "../../graphql/graphql-contentful";
import TfnInstructions from "./tfnInstructions";
import "./tfnInstructions.css";

const TfnForm: React.FC = () => {
    const {
        global: {
            themeColorDanger,
            sizeBaseUnit,
            sizeBaseX2,
            themeColorBackgroundSuccess,
            themeColorWhite,
            themeColorGrayT20,
            themeColorGrayT05,
            sizeNone,
            sizeBase,
        },
    } = getGelTokens();

    const [contentfulData, setContenfulData] = useState<any>(null);

    const claimId = window.sessionStorage.getItem("claimId") ?? "";
    const requirementId = window.sessionStorage.getItem("requirementId") ?? "";

    const { state } = useLocation();
    const navigate = useNavigate();
    const [{ ssoid, tpid, sid, aid, rid }] = useCookies(["aid", "rid", "ssoid", "tpid", "sid"]);
    const [openModal, setOpenModal] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [tfnAltRequired, setTfnAltRequired] = useState(false);
    const [isTfnInvalid, setIsTfnInvalid] = useState(false);
    const [tfnErrorMsg, setTfnErrorMsg] = useState("");
    const [addressInputManual, setAddressInputManual] = useState(false);
    const [addressData, setAddressData] = useState(null);
    const [isTitleOthers, setIsTitleOthers] = useState(false);
    const [isPostcodeError, setIsPostcodeError] = useState(false);
    const [isAddressError, setIsAddressError] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isShowTfnFieldsErrorMessage, setIsShowTfnFieldsErrorMessage] = useState<boolean>(false);
    const [isShowBirthDateFieldError, setIsShowBirthDateFieldError] = useState<boolean>(false);

    const tfnFieldRef = useRef<HTMLInputElement>(null);
    const tfnDropDownRef = useRef<HTMLSelectElement>(null);

    //tooltip controls
    const [TfnTooltipOpen, setTfnTooltipOpen] = useState(false);
    const [PaidTooltipOpen, setPaidTooltipOpen] = useState(false);
    const [LoanTooltipOpen, setLoanTooltipOpen] = useState(false);
    const [ResidentTooltipOpen, setResidentTooltipOpen] = useState(false);
    const [TaxFreeTooltipOpen, setTaxFreeTooltipOpen] = useState(false);

    const stateToNumber: Record<string, string> = {
        ACT: "0",
        NSW: "1",
        NT: "2",
        QLD: "3",
        SA: "4",
        TAS: "5",
        VIC: "6",
        WA: "7",
    };

    const { formData, onFormDataChange, setFormData } = useGelFormData({
        tfnNumber: "",
        tfnNumberAlt: "",
        title: 0,
        otherTitle: "",
        surname: "",
        firstName: "",
        otherName: "",
        streetAddress: "",
        suburb: "",
        state: 0,
        postcode: "",
        previousSurname: "",
        previousFirstName: "",
        previousOtherName: "",
        email: "",
        dateOfBirth: "",
        basisOfPayment: 0,
        residencyStatus: 0,
        taxFreeStatus: 0,
        educationLoan: 0,
        fullName: "",
        requirementId: 0,
        claimId: 0,
        declarationCheckbox: false,
        declarationName: "",
    });

    const origin = sessionStorage.getItem("origin") ?? "";

    useEffect(() => {
        if (!state) {
            sessionStorage.removeItem("origin");
            if (process.env.NODE_ENV !== "production") {
                console.log(atob(origin));
            }

            if (atob(origin).indexOf("?source=") > 0) {
                window.location.replace(atob(origin));
            } else {
                sessionStorage.removeItem("origin");
                window.location.replace(atob(origin) + "?source=TFN");
            }
        }

        axios
            .post(API.CONTENTFUL_URL, {
                query: GET_TFNPAGE_CONTENT_QUERY,
            })
            .then(async (response) => {
                setContenfulData(response.data?.data.items.items[0]);
            });
    }, []);

    const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
        setIsAddressError(false);

        if (addressData) {
            const addressList: string[] = addressData;
            if (!addressList.includes(value)) {
                setIsAddressError(true);
            } else {
                setIsAddressError(false);
            }
        }
    };

    const onAddressSearch = async (e: string): Promise<MemberAddressResponseType | void> => {
        if (e.length === 0) {
            setIsAddressError(false);
            return;
        }

        if (e.length <= 1) {
            return;
        }

        try {
            const response = await searchAddressRequest(e, { ssoid, tpid, sid, aid, rid });

            if (!Array.isArray(response)) {
                return;
            }
            console.log(response, "response");

            // @ts-ignore
            setAddressData(response.map((item) => item.addressLine));
            setIsAddressError(true);
        } catch (error) {
            console.error(error);
            setAddressInputManual(true);
        }
    };

    const onOptionClick = (address: string) => {
        if (address === "Address not listed?") {
            setAddressInputManual(true);
            setFormData({ ...formData, ["streetAddress"]: "" });
        }
    };

    const parseAddress = (address: string) => {
        const regex = /(.+),\s*(.+)\s+(\b[A-Z]{2,3}\b)\s+(\d{4})$/;
        const match = address.match(regex) ?? [];
        if (process.env.NODE_ENV !== "production") {
            console.log(match, "This is the log for the match inside the parseAddress function");
        }

        if (match) {
            // The additional regular expression in the line below was implemented according to the requirements of this ticket: https://taldelivery.atlassian.net/browse/CAMMC-381
            const streetAddress = match[1].trim().replace(/,/g, "");
            const suburb = match[2].trim();
            const stateAbbreviation = match[3].trim();
            const postcode = match[4].trim();

            const state = stateToNumber[stateAbbreviation] !== undefined ? stateToNumber[stateAbbreviation] : 0;

            return {
                streetAddress,
                suburb,
                state,
                postcode,
            };
        }
    };

    const onClickCancel = () => {
        setOpenModal(true);
    };

    const onClickCancelConfirm = () => {
        const origin = sessionStorage.getItem("origin") ?? "";

        if (atob(origin).indexOf("?source=") > 0) {
            window.location.replace(atob(origin));
        } else {
            sessionStorage.removeItem("origin");
            window.location.replace(atob(origin) + "?source=TFN");
        }
    };

    const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));

        setIsTitleOthers(value === "5");
    };

    const onChangePostcode = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.value.length < 4 && e.target.value.length > 0) {
            setIsPostcodeError(true);
        } else {
            setIsPostcodeError(false);
        }
    };

    const onClickSearchAddress = () => {
        setAddressInputManual(false);
    };

    const onChangeDeclaration = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.value !== "" && formData.declarationCheckbox) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    };

    const tfnErrorMessageHandler = () => {
        const { tfnNumber, tfnNumberAlt } = formData;

        if (!tfnNumber && !tfnNumber) {
            setIsShowTfnFieldsErrorMessage(true);
        }

        if (tfnNumber) {
            setIsShowTfnFieldsErrorMessage(false);
        }

        if (tfnNumberAlt) {
            setIsShowTfnFieldsErrorMessage(false);
        }
    };

    const onChangeTfnAlt = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // The set state function is called before checking for a valid value (the line below)
        // because we want to set an empty value for the altTfnNumberState field due to the nature of the select component.
        // The first element in the dropdown menu is also a value (an undefined value), and the condition check won't allow the undefined value to be passed
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));

        if (value) {
            setIsShowTfnFieldsErrorMessage(false);
            setTfnAltRequired(false);
            setIsTfnInvalid(false);

            // Reset the state of the TFN number field after setting the value in the TFN Alternative form.
            setFormData((prevState: any) => ({
                ...prevState,
                tfnNumber: "",
            }));
        }

        if (!value && !formData.tfnNumber) {
            setIsShowTfnFieldsErrorMessage(true);
            setTfnAltRequired(true);
        }
    };

    const onChangeTfn = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // In the line below, we are resetting the state for the tfnNumberAlt (dropdown dedicated to the TFN) field, which is necessary behavior if we are typing something in the tfn number field.
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value,
            tfnNumberAlt: "",
        }));

        if (formData.tfnNumber) {
            setIsShowTfnFieldsErrorMessage(false);
        }

        if (!value) {
            setIsShowTfnFieldsErrorMessage(true);
        } else {
            setIsShowTfnFieldsErrorMessage(false);
        }

        const tfn = value;
        if (value === "") {
            setIsTfnInvalid(false);
            setTfnErrorMsg("");
        } else {
            setTfnAltRequired(false);
            if (tfn.length < 9) {
                //tfn needs to be at least 9 digits
                setIsTfnInvalid(true);
                setTfnErrorMsg(contentfulData?.errorMessageContent.tfnMinLength);
            } else {
                var digits = tfn.split("").map((x: string) => parseInt(x));

                var sum =
                    digits[0] * 1 +
                    digits[1] * 4 +
                    digits[2] * 3 +
                    digits[3] * 7 +
                    digits[4] * 5 +
                    digits[5] * 8 +
                    digits[6] * 6 +
                    digits[7] * 9 +
                    digits[8] * 10;
                var remainder = sum % 11;

                if (remainder === 0) {
                    //valid tfn
                    setIsTfnInvalid(false);
                } else {
                    //invalid tfn
                    setIsTfnInvalid(true);
                    setTfnErrorMsg(contentfulData?.errorMessageContent.tfnInvalid);
                }
            }
        }
    };

    const tfnFormFields = (): React.ReactNode => {
        return (
            <>
                <GelFormField ref={tfnFieldRef}>
                    <StyledGelNumberInputWrapper
                        onBlur={() => tfnErrorMessageHandler()}
                        onFocus={() => setIsShowTfnFieldsErrorMessage(false)}
                    >
                        <GelNumberInput
                            small
                            name="tfnNumber"
                            value={formData.tfnNumber}
                            onChange={onChangeTfn}
                            maxLength={9}
                            error={isTfnInvalid || tfnAltRequired}
                            noFormat
                        />
                    </StyledGelNumberInputWrapper>

                    {isShowTfnFieldsErrorMessage && (
                        <ErrorText>{contentfulData?.errorMessageContent.requiredQuestion}</ErrorText>
                    )}

                    {isTfnInvalid && (
                        <GelBoxLayout marginTop="small">
                            <GelCaption
                                condensed
                                style={{
                                    color: themeColorDanger,
                                }}
                            >
                                {tfnErrorMsg}
                            </GelCaption>
                        </GelBoxLayout>
                    )}
                </GelFormField>

                <GelFormField label="OR" ref={tfnDropDownRef}>
                    <StyledGelNumberInputWrapper onBlur={() => tfnErrorMessageHandler()}>
                        <GelSelect
                            name="tfnNumberAlt"
                            value={formData.tfnNumberAlt}
                            onChange={onChangeTfnAlt}
                            placeholder="Please select"
                            error={tfnAltRequired}
                            options={[
                                {
                                    label: contentfulData?.tfnQuestionnaire.tfnExemptionOptions.separateApplication,
                                    value: "1",
                                },
                                {
                                    label: contentfulData?.tfnQuestionnaire.tfnExemptionOptions.underage,
                                    value: "2",
                                },
                                {
                                    label: contentfulData?.tfnQuestionnaire.tfnExemptionOptions.pension,
                                    value: "3",
                                },
                            ]}
                        />
                    </StyledGelNumberInputWrapper>

                    {tfnAltRequired && (
                        <GelBoxLayout marginTop="small">
                            <GelCaption
                                condensed
                                style={{
                                    color: themeColorDanger,
                                }}
                            >
                                {contentfulData?.errorMessageContent.tfnAltReq}
                            </GelCaption>
                        </GelBoxLayout>
                    )}
                </GelFormField>
            </>
        );
    };

    const handleBasisOfPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (!value) {
            return;
        }

        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleBirthDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target;

        // Additional check for invalid year input
        if (value.length > 10) {
            return;
        }

        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));

        const parsedInputAge = new Date(value);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - parsedInputAge.getFullYear();

        if (process.env.NODE_ENV !== "production") {
            console.log(parsedInputAge, "log for a parsed input age");
        }

        const isValidAge = (age: number) => age >= 16 && age <= 120;

        if (Number.isNaN(age) || isValidAge(age)) {
            setIsShowBirthDateFieldError(false);
        } else {
            setIsShowBirthDateFieldError(true);
        }
    };

    const paymentTaxLoanForms = (): React.ReactNode => {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: sizeBaseX2,
                    }}
                >
                    <GelLabel marginRight="small">{contentfulData?.tfnQuestionnaire.paymentBasisFieldText}</GelLabel>
                    <GelIcon
                        name="AlertCircle"
                        onClick={() => {
                            setPaidTooltipOpen(true);
                        }}
                        width={sizeBaseX2 * 2.5}
                        height={sizeBaseX2 * 2.5}
                        color={themeColorBackgroundSuccess}
                    />
                </div>

                <GelFormField label="">
                    <GelSelect
                        name="basisOfPayment"
                        value={formData.basisOfPayment}
                        onChange={handleBasisOfPayment}
                        placeholder="Please select"
                        options={[
                            { label: "Full time employment", value: "1" },
                            { label: "Part time employment", value: "2" },
                            { label: "Labour hire", value: "3" },
                            {
                                label: "Superannuation or annuity income stream",
                                value: "4",
                            },
                            { label: "Casual employment", value: "5" },
                        ]}
                        required
                        errorMsg={{
                            required: contentfulData?.errorMessageContent.requiredQuestion,
                        }}
                    />
                </GelFormField>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: sizeBaseX2,
                    }}
                >
                    <GelLabel marginRight="small">Are you:</GelLabel>
                    <GelIcon
                        name="AlertCircle"
                        onClick={() => {
                            setResidentTooltipOpen(true);
                        }}
                        width={sizeBaseX2 * 2.5}
                        height={sizeBaseX2 * 2.5}
                        color={themeColorBackgroundSuccess}
                    />
                </div>
                <GelFormField label="">
                    <GelSelect
                        name="residencyStatus"
                        defaultValue={formData.residencyStatus}
                        onChange={onFormDataChange}
                        placeholder="Please select"
                        options={[
                            {
                                label: contentfulData?.tfnQuestionnaire.taxPurposeOptions.ausResident,
                                value: "1",
                            },
                            {
                                label: contentfulData?.tfnQuestionnaire.taxPurposeOptions.foreignResident,
                                value: "2",
                            },
                            {
                                label: contentfulData?.tfnQuestionnaire.taxPurposeOptions.workingHoliday,
                                value: "3",
                            },
                        ]}
                        required
                        errorMsg={{
                            required: contentfulData?.errorMessageContent.requiredQuestion,
                        }}
                    />
                </GelFormField>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: sizeBaseX2,
                    }}
                >
                    <GelLabel marginRight="small">{contentfulData?.tfnQuestionnaire.taxThresholdQuestionText}</GelLabel>
                    <GelIcon
                        name="AlertCircle"
                        onClick={() => {
                            setTaxFreeTooltipOpen(true);
                        }}
                        width={sizeBaseX2 * 2.5}
                        height={sizeBaseX2 * 2.5}
                        color={themeColorBackgroundSuccess}
                    />
                </div>

                <GelParagraph>
                    <div style={{ marginTop: sizeBaseUnit }}>
                        {contentfulData?.tfnQuestionnaire.taxThresholdInfoText1}
                    </div>

                    <div style={{ marginTop: sizeBaseUnit }}>
                        Answer <b>no</b> here if you are a foreign resident or working holiday maker, except if you are
                        a foreign resident in receipt of an Australian Government pension or allowance.
                    </div>
                </GelParagraph>
                <GelFormField label="">
                    <GelSelect
                        name="taxFreeStatus"
                        value={formData.taxFreeStatus}
                        onChange={onFormDataChange}
                        placeholder="Please select"
                        options={[
                            { label: "No", value: "0" },
                            { label: "Yes", value: "1" },
                        ]}
                        required
                        requiredErrorMsg={contentfulData?.errorMessageContent.requiredQuestion}
                    />
                </GelFormField>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: sizeBaseX2,
                    }}
                >
                    <GelLabel marginRight="small">{contentfulData?.tfnQuestionnaire.debtQuestionText}</GelLabel>
                    <GelIcon
                        name="AlertCircle"
                        onClick={() => {
                            setLoanTooltipOpen(true);
                        }}
                        width={sizeBaseX2 * 2.5}
                        height={sizeBaseX2 * 2.5}
                        color={themeColorBackgroundSuccess}
                    />
                </div>
                <GelParagraph>
                    <div style={{ marginTop: sizeBaseUnit }}>
                        {contentfulData?.tfnQuestionnaire.debtQuestionInfoText}
                    </div>
                </GelParagraph>
                <GelFormField label="">
                    <GelSelect
                        name="educationLoan"
                        value={formData.educationLoan}
                        onChange={onFormDataChange}
                        placeholder="Please select"
                        options={[
                            { label: "No", value: "0" },
                            { label: "Yes", value: "1" },
                        ]}
                        required
                        errorMsg={{
                            required: contentfulData?.errorMessageContent.requiredQuestion,
                        }}
                    />
                </GelFormField>

                <GelColumnLayout verticalGutter={sizeBaseUnit * 6} marginBottom="medium">
                    <div className="line"></div>

                    <GelLabel>DECLARATION by payee:</GelLabel>
                    <GelCheckboxGroup
                        name="" // Makes it a managed component
                        vertical
                        gap="small"
                        onChange={onFormDataChange}
                        required
                        min={1}
                        errorMsg={{
                            required: contentfulData?.errorMessageContent.required,
                        }}
                    >
                        <GelCheckbox name="declarationCheckbox" checked={formData.declarationCheckbox}>
                            I declare the information I have given is true and correct.
                        </GelCheckbox>
                    </GelCheckboxGroup>
                    <GelFormField label="Declaration by payee(type full legal name)">
                        <GelTextInput
                            name="declarationName"
                            onChange={onChangeDeclaration}
                            required
                            requiredErrorMsg={contentfulData?.errorMessageContent.required}
                        ></GelTextInput>
                    </GelFormField>
                </GelColumnLayout>
            </>
        );
    };

    const handleOtherTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const otherTitleForm = (): React.ReactNode | void => {
        if (!isTitleOthers) {
            formData.otherTitle = "";
            return;
        }

        return (
            <GelFormField label="Other" marginTop="small">
                <GelTextInput
                    name="otherTitle"
                    value={formData.otherTitle}
                    onChange={handleOtherTitleChange}
                    maxLength={20}
                />
            </GelFormField>
        );
    };

    const handleFormSubmit = async (): Promise<void> => {
        //scroll up to tfn ques if invalid
        if (formData.tfnNumberAlt === "" && formData.tfnNumber === "") {
            setTfnAltRequired(true);
            tfnFieldRef.current?.scrollIntoView();
        } else {
            setTfnAltRequired(false);
        }

        //show spinner and text
        setIsWaiting(true);

        //api call

        if (process.env.NODE_ENV !== "production") {
            console.log(formData.streetAddress);
        }
        const variables = {
            tfnSubmitModel: {
                tfnNumber: formData.tfnNumber,
                tfnNumberAlt: Number.isNaN(parseInt(formData.tfnNumberAlt, 10))
                    ? 0
                    : parseInt(formData.tfnNumberAlt, 10),
                title: parseInt(formData.title),
                otherTitle: formData.otherTitle,
                surname: formData.surname,
                firstName: formData.firstName,
                otherName: formData.otherName,
                streetAddress: addressInputManual
                    ? formData.streetAddress
                    : parseAddress(formData.streetAddress)?.streetAddress,
                suburb: addressInputManual ? formData.suburb : parseAddress(formData.streetAddress)?.suburb,
                state: parseInt(addressInputManual ? formData.state : parseAddress(formData.streetAddress)?.state),
                postcode: addressInputManual ? formData.postcode : parseAddress(formData.streetAddress)?.postcode,
                previousSurname: formData.previousSurname,
                previousFirstName: formData.previousFirstName,
                previousOtherName: formData.previousOtherName,
                email: formData.email,
                dateOfBirth: formData.dateOfBirth,
                basisOfPayment: parseInt(formData.basisOfPayment),
                residencyStatus: parseInt(formData.residencyStatus),
                taxFreeStatus: parseInt(formData.taxFreeStatus),
                educationLoan: parseInt(formData.educationLoan),
                fullName: formData.declarationName,
                requirementId: parseInt(requirementId),
                claimId: parseInt(claimId),
            },
        };

        if (process.env.NODE_ENV !== "production") {
            console.log(variables, "log for the whole bunch of variables");
        }

        try {
            await axios.post(
                API.BASEURL,
                {
                    query: SUBMIT_TFN_FORM_QUERY,
                    variables: variables,
                },
                {
                    headers: {
                        "x-aid": aid,
                        "x-rid": rid,
                        ssoid,
                        tpid,
                        sid,
                    },
                }
            );

            navigate("/success", { state: { auth: true } });
        } catch (error) {
            console.error(error);
            navigate("/error");
        }
    };

    return (
        <div
            style={{
                backgroundColor: themeColorGrayT05,
            }}
        >
            <Header title={contentfulData?.tfnPageTitle} />
            <GelContainerLite
                gutter="small"
                style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                }}
            >
                <GelBoxLayout
                    gap="medium"
                    alignment="start"
                    space="auto"
                    distribution="spaceBetween"
                    vertical="true"
                    padding="large"
                    margin="large"
                    style={{
                        marginTop: -sizeBaseUnit * 6,
                        backgroundColor: themeColorWhite,
                        border: "1px solid",
                        borderColor: themeColorGrayT20,
                    }}
                >
                    <TfnInstructions />
                    <GelForm labelAtTop disableOnSubmit onSubmit={handleFormSubmit}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                paddingBottom: sizeBaseX2,
                            }}
                        >
                            <GelLabel marginRight="small">
                                {contentfulData?.tfnQuestionnaire.taxFileNumberQuestion}
                            </GelLabel>
                            <GelIcon
                                name="AlertCircle"
                                onClick={() => {
                                    setTfnTooltipOpen(true);
                                }}
                                width={sizeBaseX2 * 2.5}
                                height={sizeBaseX2 * 2.5}
                                color={themeColorBackgroundSuccess}
                            />
                        </div>

                        {tfnFormFields()}

                        <GelColumnLayout marginBottom="small" verticalGutter={sizeNone}>
                            <GelLabel>What is your name?</GelLabel>
                            <GelFormField label="Title">
                                <GelSelect
                                    name="title"
                                    value={formData.title}
                                    onChange={onChangeTitle}
                                    placeholder="Please select"
                                    options={[
                                        { label: "Mr", value: "1" },
                                        { label: "Mrs", value: "2" },
                                        { label: "Miss", value: "3" },
                                        { label: "Ms", value: "4" },
                                        { label: "Other", value: "5" },
                                    ]}
                                    required
                                    requiredErrorMsg={contentfulData?.errorMessageContent.requiredQuestion}
                                    marginBottom="medium"
                                />
                            </GelFormField>
                            {otherTitleForm()}
                            {/* {isTitleOthers && (
                <GelFormField label="Other" marginTop="small">
                  <GelTextInput
                    name="otherTitle"
                    value={formData.otherTitle}
                    onChange={onChangeOtherTitle}
                    maxLength={20}
                  />
                </GelFormField>
              )} */}
                        </GelColumnLayout>

                        <GelFormField label={contentfulData?.tfnQuestionnaire.surnameFieldText}>
                            <GelTextInput
                                name="surname"
                                value={formData.surname}
                                onChange={onFormDataChange}
                                maxLength={60}
                                required
                                requiredErrorMsg={contentfulData?.errorMessageContent.required}
                            />
                        </GelFormField>

                        <GelFormField label={contentfulData?.tfnQuestionnaire.firstnameFieldText}>
                            <GelTextInput
                                name="firstName"
                                value={formData.firstName}
                                onChange={onFormDataChange}
                                maxLength={60}
                                required
                                requiredErrorMsg={contentfulData?.errorMessageContent.required}
                            />
                        </GelFormField>

                        <GelFormField label={contentfulData?.tfnQuestionnaire.othernameFieldText}>
                            <GelTextInput
                                name="otherName"
                                value={formData.otherName}
                                onChange={onFormDataChange}
                                maxLength={60}
                                noFormat
                            />
                        </GelFormField>

                        <GelColumnLayout>
                            <GelLabel marginRight="small">
                                {contentfulData?.tfnQuestionnaire.addressSectionHeading}
                            </GelLabel>
                            {!addressInputManual ? (
                                <GelFormField label={contentfulData?.tfnQuestionnaire.addressFieldText.address}>
                                    <GelAutoCompleteInput
                                        name="streetAddress"
                                        value={formData.streetAddress}
                                        onChange={onAddressChange}
                                        data={addressData}
                                        onSearch={onAddressSearch}
                                        onOptionClick={onOptionClick}
                                        maxHeight={`${sizeBase * 50}px`}
                                        required
                                        error={isAddressError}
                                        errorMsg={{
                                            required: contentfulData?.errorMessageContent.required,
                                        }}
                                    />

                                    {isAddressError && (
                                        <GelBoxLayout marginTop="small">
                                            <GelCaption
                                                condensed
                                                style={{
                                                    color: themeColorDanger,
                                                }}
                                            >
                                                {contentfulData?.errorMessageContent.invalidAddress}
                                            </GelCaption>
                                        </GelBoxLayout>
                                    )}
                                </GelFormField>
                            ) : (
                                <div>
                                    <GelFormField label={contentfulData?.tfnQuestionnaire.addressFieldText.address}>
                                        <GelTextInput
                                            name="streetAddress"
                                            value={formData.streetAddress}
                                            onChange={onFormDataChange}
                                            maxLength={80}
                                            noFormat
                                        />
                                    </GelFormField>

                                    <GelFormField label={contentfulData?.tfnQuestionnaire.addressFieldText.suburb}>
                                        <GelTextInput
                                            name="suburb"
                                            value={formData.suburb}
                                            onChange={onFormDataChange}
                                            maxLength={30}
                                            noFormat
                                        />
                                    </GelFormField>

                                    <GelFormField label={contentfulData?.tfnQuestionnaire.addressFieldText.state}>
                                        <GelSelect
                                            name="state"
                                            value={formData.state}
                                            onChange={onFormDataChange}
                                            placeholder="Please select"
                                            options={[
                                                { label: "ACT", value: "0" },
                                                { label: "NSW", value: "1" },
                                                { label: "NT", value: "2" },
                                                { label: "QLD", value: "3" },
                                                { label: "SA", value: "4" },
                                                { label: "TAS", value: "5" },
                                                { label: "VIC", value: "6" },
                                                { label: "WA", value: "7" },
                                            ]}
                                            required
                                            errorMsg={{
                                                required: contentfulData?.errorMessageContent.required,
                                            }}
                                        />
                                    </GelFormField>

                                    <GelFormField label={contentfulData?.tfnQuestionnaire.addressFieldText.postcode}>
                                        <GelNumberInput
                                            name="postcode"
                                            value={formData.postcode}
                                            onChange={onChangePostcode}
                                            maxLength={4}
                                            error={isPostcodeError}
                                            required
                                            requiredErrorMsg={contentfulData?.errorMessageContent.required}
                                            noFormat
                                        />
                                        {isPostcodeError && (
                                            <GelBoxLayout marginTop="small" marginBottom="small">
                                                <GelCaption
                                                    condensed
                                                    style={{
                                                        color: themeColorDanger,
                                                    }}
                                                >
                                                    {contentfulData?.errorMessageContent.invalidPostcode}
                                                </GelCaption>
                                            </GelBoxLayout>
                                        )}
                                    </GelFormField>
                                    <GelLink onClick={onClickSearchAddress} marginTop="small">
                                        Search Address
                                    </GelLink>
                                </div>
                            )}
                        </GelColumnLayout>

                        <GelColumnLayout marginTop="large" marginBottom="small">
                            <GelLabel marginRight="small">
                                {contentfulData?.tfnQuestionnaire.previousNameSectionHeading}
                            </GelLabel>
                            <GelFormField label={contentfulData?.tfnQuestionnaire.surnameFieldText}>
                                <GelTextInput
                                    name="previousSurname"
                                    value={formData.previousSurname}
                                    onChange={onFormDataChange}
                                    maxLength={60}
                                    noFormat
                                />
                            </GelFormField>
                        </GelColumnLayout>

                        <GelFormField label={contentfulData?.tfnQuestionnaire.firstnameFieldText}>
                            <GelTextInput
                                name="previousFirstName"
                                value={formData.previousFirstName}
                                onChange={onFormDataChange}
                                maxLength={60}
                                noFormat
                            />
                        </GelFormField>

                        <GelFormField label={contentfulData?.tfnQuestionnaire.othernameFieldText}>
                            <GelTextInput
                                name="previousOtherName"
                                value={formData.previousOtherName}
                                onChange={onFormDataChange}
                                maxLength={60}
                                noFormat
                            />
                        </GelFormField>

                        <GelFormField label={contentfulData?.tfnQuestionnaire.primaryEmailAddressFieldText}>
                            <GelEmailInput
                                name="email"
                                value={formData.email}
                                onChange={onFormDataChange}
                                maxLength={76}
                                noFormat
                                required
                                errorMsg={{
                                    required: contentfulData?.errorMessageContent.required,
                                    format: contentfulData?.errorMessageContent.invalidEmail,
                                }}
                            />
                        </GelFormField>

                        <GelFormField label={contentfulData?.tfnQuestionnaire.dobFieldText}>
                            <GelStandardDateInput
                                name="dateOfBirth"
                                value={formData.dateOfBirth}
                                onChange={handleBirthDateChange}
                                required
                                type="date"
                                lt="9999-12-31"
                                errorMsg={{
                                    required: contentfulData?.errorMessageContent.requiredQuestion,
                                    compare: contentfulData?.errorMessageContent.invalidDateOfBirth,
                                    format: contentfulData?.errorMessageContent.invalidDateOfBirth,
                                }}
                            />
                            {isShowBirthDateFieldError && (
                                <ErrorText>The Date of Birth you have provided is invalid.</ErrorText>
                            )}
                        </GelFormField>

                        {paymentTaxLoanForms()}

                        <GelBoxLayout alignment="start" width={sizeBaseUnit * 60}>
                            <GelFormField>
                                <GelButton
                                    name="" // Makes it a managed component
                                    primary
                                    medium
                                    submit
                                    disabled={isSubmitDisabled}
                                >
                                    Submit
                                </GelButton>
                            </GelFormField>

                            <GelFormField>
                                <GelButton
                                    name="" // Makes it a managed component
                                    secondary
                                    medium
                                    onClick={onClickCancel}
                                >
                                    Cancel
                                </GelButton>
                            </GelFormField>
                        </GelBoxLayout>

                        <GelBoxLayout marginTop="small">
                            {isWaiting && (
                                <div style={{ display: "flex" }}>
                                    <GelSpinner small left />
                                    <GelParagraph paddingLeft="large">
                                        Please wait while the form is being submitted
                                    </GelParagraph>
                                </div>
                            )}
                        </GelBoxLayout>
                    </GelForm>
                </GelBoxLayout>

                <GelModal
                    width="sm"
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    title="Cancel"
                    sticky
                    action={
                        <GelBoxLayout gap="small" space="auto">
                            <GelButton secondary medium width={sizeBaseUnit * 22} onClick={() => setOpenModal(false)}>
                                Close
                            </GelButton>

                            <GelButton primary medium width={sizeBaseUnit * 22} onClick={onClickCancelConfirm}>
                                Confirm
                            </GelButton>
                        </GelBoxLayout>
                    }
                >
                    <GelParagraph>The details you have entered on this form will not be submitted.</GelParagraph>

                    <GelParagraph> Are you sure you want to cancel?</GelParagraph>
                </GelModal>

                <TooltipModal openModal={TfnTooltipOpen} setOpenModal={setTfnTooltipOpen} content={TfnNumberTooltip} />

                <TooltipModal openModal={PaidTooltipOpen} setOpenModal={setPaidTooltipOpen} content={PaidTooltip} />

                <TooltipModal openModal={LoanTooltipOpen} setOpenModal={setLoanTooltipOpen} content={LoanTooltip} />

                <TooltipModal
                    openModal={ResidentTooltipOpen}
                    setOpenModal={setResidentTooltipOpen}
                    content={ResidentTooltip}
                />

                <TooltipModal
                    openModal={TaxFreeTooltipOpen}
                    setOpenModal={setTaxFreeTooltipOpen}
                    content={TaxFreeTooltip}
                />
            </GelContainerLite>
            <Footer />
        </div>
    );
};

const StyledGelNumberInputWrapper = styled.div``;

const ErrorText = styled.div`
    font-family: "Pluto Sans Condensed", Arial, sans-serif;
    color: #e20f1a;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.125;
    letter-spacing: -0.25px;
    margin-top: 8px;
`;

export default TfnForm;
