import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import HttpClientService from "./common/api/http-client.provider";
import { useCallback } from "react";
import config from "./config";
import { Outlet, useNavigate } from "react-router-dom";
import AppRoutes from "./pages/app.routing";

function App() {
    const oktaAuth = new OktaAuth(config.oidc);
    const navigate = useNavigate();

    const restoreOriginalUri = useCallback((oktaAuth: OktaAuth, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
            replace: true,
        });
    }, []);

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <HttpClientService>
                <AppRoutes />
                <Outlet />
            </HttpClientService>
        </Security>
    );
}

export default App;
